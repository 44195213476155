// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Sentry from "../../utils/Sentry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as LocalFile from "../../utils/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FailurePage from "../components/pages/FailurePage.js";
import * as ResetAppButton from "../components/ResetAppButton.js";
import * as JsxRuntime from "react/jsx-runtime";

function LocalFileDBContext$Migrate(props) {
  var onDone = props.onDone;
  var dbname = props.dbname;
  React.useEffect((function () {
          Prelude.thenDo(LocalFile.Database.connect(dbname), (function (result) {
                  if (result.TAG === "Ok") {
                    return onDone({
                                TAG: "Ok",
                                _0: undefined
                              });
                  }
                  var error = result._0;
                  console.error("Could open DB", dbname, error);
                  Sentry.captureException(error);
                  onDone({
                        TAG: "Error",
                        _0: error
                      });
                }));
        }), []);
  return null;
}

var Migrate = {
  make: LocalFileDBContext$Migrate
};

function LocalFileDBContext(props) {
  var __dbname = props.dbname;
  var dbname = __dbname !== undefined ? __dbname : "photocuba-fs";
  var match = React.useState(function () {
        return "InitDB";
      });
  var setState = match[1];
  switch (match[0]) {
    case "InitDB" :
        return JsxRuntime.jsx(LocalFileDBContext$Migrate, {
                    dbname: dbname,
                    onDone: (function (result) {
                        if (result.TAG === "Ok") {
                          return setState(function (param) {
                                      return "DoneInitDB";
                                    });
                        } else {
                          return setState(function (param) {
                                      return "ErrorInitDB";
                                    });
                        }
                      })
                  });
    case "DoneInitDB" :
        return props.children;
    case "ErrorInitDB" :
        var reasons = [{
            NAME: "msg",
            VAL: [
              "errors.init.description",
              "There was an error while initializing the\n         application's database.  Unfortunately, this means that the database is\n         possibly corrupt and we need to reset it."
            ]
          }];
        return JsxRuntime.jsx(FailurePage.make, {
                    title: {
                      NAME: "msg",
                      VAL: [
                        "errors.init.title",
                        "Initialization error"
                      ]
                    },
                    reasons: reasons,
                    children: Caml_option.some(JsxRuntime.jsx(ResetAppButton.make, {
                              dbnames: [dbname]
                            }))
                  });
    
  }
}

var make = LocalFileDBContext;

export {
  Migrate ,
  make ,
}
/* react Not a pure module */
