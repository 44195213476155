// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function ConfirmDeleteDialog(props) {
  var onDelete = props.onDelete;
  var onCancel = props.onCancel;
  var label = props.label;
  var content = props.content;
  var match = React.useState(function () {
        return "";
      });
  var setMessage = match[1];
  var message = match[0];
  var handleTextChange = function (ev) {
    var target = ev.currentTarget;
    var value;
    try {
      value = target.value;
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      console.error(e);
      return ;
    }
    if (value === undefined) {
      return ;
    }
    var value$1 = Caml_option.valFromOption(value);
    setMessage(function (param) {
          return value$1.toLowerCase();
        });
  };
  var confirmMessage = Curry._2(Prelude.OptionExported.$$Option.map, props.confirmMessage, (function (prim) {
          return prim.toLowerCase();
        }));
  var handleDeleteClick = function (param) {
    onDelete();
  };
  var label$1;
  if (label !== undefined) {
    label$1 = JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: label
        });
  } else if (confirmMessage !== undefined) {
    var word = confirmMessage.toUpperCase();
    label$1 = JsxRuntime.jsxs(ReactI18next.Trans, {
          i18nKey: "confirm-delete-dialog.type-word",
          children: [
            "Type ",
            JsxRuntime.jsx("b", {
                  children: "{{word}}"
                })
          ],
          count: word
        });
  } else {
    label$1 = JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "str",
            VAL: "Confirm"
          }
        });
  }
  return JsxRuntime.jsxs(Dialog, {
              className: props.className,
              open: props.open_,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: Prelude.default(props.title, {
                                      NAME: "msg",
                                      VAL: [
                                        "confirm-delete-dialog.title",
                                        "Confirm deletion"
                                      ]
                                    })
                              }))
                    }),
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        content !== undefined ? Caml_option.valFromOption(content) : (
                            confirmMessage !== undefined ? JsxRuntime.jsx(DialogContentText, {
                                    children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                                              i18nKey: "confirm-delete-dialog.explanation.type-word",
                                              children: [
                                                "Confirm you are sure you want to delete this item, by typing ",
                                                JsxRuntime.jsx("b", {
                                                      children: confirmMessage
                                                    }),
                                                " in the input below."
                                              ]
                                            }))
                                  }) : JsxRuntime.jsx(DialogContentText, {
                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                              i18nKey: "confirm-delete-dialog.explanation.confirm",
                                              children: "Confirm you are sure you want to delete this item."
                                            }))
                                  })
                          ),
                        confirmMessage !== undefined ? JsxRuntime.jsx(TextField, {
                                margin: "dense",
                                autoFocus: true,
                                fullWidth: true,
                                label: Caml_option.some(label$1),
                                onChange: handleTextChange,
                                value: message,
                                variant: "standard"
                              }) : null
                      ]
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: Prelude.default(props.cancelLabel, {
                                              NAME: "str",
                                              VAL: "Cancel"
                                            })
                                      })),
                              onClick: (function (param) {
                                  onCancel();
                                }),
                              variant: "text"
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: Prelude.default(props.confirmLabel, {
                                              NAME: "str",
                                              VAL: "Remove"
                                            })
                                      })),
                              onClick: handleDeleteClick,
                              color: "error",
                              disabled: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, confirmMessage, (function (expected) {
                                          return expected !== message;
                                        })), false),
                              variant: "text"
                            })
                      ]
                    })
              ],
              fullScreen: props.fullScreen,
              fullWidth: props.fullWidth,
              maxWidth: props.maxWidth,
              onClose: (function (param, param$1) {
                  onCancel();
                }),
              sx: props.sx
            });
}

var make = ConfirmDeleteDialog;

export {
  make ,
}
/* react Not a pure module */
