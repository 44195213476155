// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as APIMaker from "../../utils/APIMaker.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "root_folder_id",
      "String"
    ],
    [
      "trash_folder_id",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Definition = {
  url: "ns",
  Deserializer: Deserializer
};

var include = APIMaker.MakeBackendReadonlyAPIEndpoint(Definition);

var Deserializer$1 = include.Deserializer;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var getAllWithPost = include.getAllWithPost;

var get = include.get;

var options = include.options;

export {
  Definition ,
  Deserializer$1 as Deserializer,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  getAllWithPost ,
  get ,
  options ,
}
/* Deserializer Not a pure module */
