// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React from "@sentry/react";
import * as Replay from "@sentry/replay";
import * as Tracing from "@sentry/tracing";
import * as Integrations from "@sentry/integrations";

var instance = {
  contents: undefined
};

function make(options) {
  instance.contents = Caml_option.some(new Replay.Replay(options));
  return instance.contents;
}

function start() {
  Prelude.OptionExported.$$Option.$$do(instance.contents, (function (prim) {
          prim.start();
        }));
}

function stop() {
  Prelude.OptionExported.$$Option.$$do(instance.contents, (function (prim) {
          prim.stop();
        }));
}

function setUser(user) {
  stop();
  React.setUser({
        id: user.id,
        email: user.email,
        username: user.email
      });
  start();
}

function init(prim) {
  React.init(prim);
}

function setTag(prim0, prim1) {
  React.setTag(prim0, prim1);
}

function captureException(prim) {
  React.captureException(prim);
}

function withSentryReactRouterV6Routing(prim) {
  return React.withSentryReactRouterV6Routing(prim);
}

var RoutingInstrumentation = {
  make: (function (prim0, prim1, prim2, prim3, prim4) {
      return React.reactRouterV6Instrumentation(prim0, prim1, prim2, prim3, prim4);
    })
};

function BrowserTracing_make(prim) {
  return new (Tracing.Integrations.BrowserTracing)(prim);
}

function BrowserTracing_integration(prim) {
  return prim;
}

var BrowserTracing = {
  make: BrowserTracing_make,
  integration: BrowserTracing_integration
};

function Offline_make(prim) {
  return new Integrations.Offline(prim);
}

function Offline_integration(prim) {
  return prim;
}

var Offline = {
  make: Offline_make,
  integration: Offline_integration
};

function Replay_integration(prim) {
  return prim;
}

var Replay$1 = {
  make: make,
  start: start,
  stop: stop,
  integration: Replay_integration
};

export {
  init ,
  setTag ,
  setUser ,
  captureException ,
  withSentryReactRouterV6Routing ,
  RoutingInstrumentation ,
  BrowserTracing ,
  Offline ,
  Replay$1 as Replay,
}
/* @sentry/react Not a pure module */
