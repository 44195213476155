// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Sentry from "../../utils/Sentry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as MuiTheme from "../../../libs/MuiTheme.js";
import * as AboutView from "../../apps/AboutView.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../context/UserContext.js";
import * as Applications from "../../apps/Applications.js";
import * as NotFoundPage from "../components/pages/NotFoundPage.js";
import * as FsBrowserView from "../../apps/catalog/FsBrowserView.js";
import * as PhotocubaI18N from "./PhotocubaI18N.js";
import * as SharedDBContext from "../context/SharedDBContext.js";
import * as ClipboardManager from "../../apps/catalog/context/ClipboardManager.js";
import * as NamespaceContext from "../context/NamespaceContext.js";
import * as UserSettingsView from "../../apps/usersettings/UserSettingsView.js";
import * as ReactRouterDom from "react-router-dom";
import * as SearchCatalogView from "../../apps/search/SearchCatalogView.js";
import * as TagsManagerRouter from "../../apps/tags/TagsManagerRouter.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocalFileDBContext from "../context/LocalFileDBContext.js";
import * as AccountsManagerView from "../../apps/accounts/AccountsManagerView.js";
import * as UntaggedBrowserView from "../../apps/untagged/UntaggedBrowserView.js";
import * as Styles from "@mui/material/styles";
import * as CollectionsManagerRouter from "../../apps/collections/CollectionsManagerRouter.js";
import * as UserPreferredColorScheme from "../context/UserPreferredColorScheme.js";

function PhotocubaApp$Modules$RedirectToModule(props) {
  var navigate = ReactRouterDom.useNavigate();
  var perms = UserContext.useUserPermissionsClaim();
  var app = React.useMemo((function () {
          return Prelude.$$Array.first(Applications.getUserApplications(perms, [
                          "app",
                          "config"
                        ]));
        }), [perms]);
  React.useEffect(function () {
        Prelude.OptionExported.$$Option.$$do(app, (function (app) {
                navigate(app.path);
              }));
      });
  return null;
}

var make = Sentry.withSentryReactRouterV6Routing(ReactRouterDom.Routes);

function PhotocubaApp$PhotocubaRoutes(props) {
  return JsxRuntime.jsx(ClipboardManager.Contents.make, {
              children: JsxRuntime.jsx(ReactRouterDom.BrowserRouter, {
                    children: Caml_option.some(JsxRuntime.jsxs(make, {
                              children: [
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/",
                                      element: JsxRuntime.jsx(PhotocubaApp$Modules$RedirectToModule, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/fs/*",
                                      element: JsxRuntime.jsx(FsBrowserView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/untagged/*",
                                      element: JsxRuntime.jsx(UntaggedBrowserView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/tags/*",
                                      element: JsxRuntime.jsx(TagsManagerRouter.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/collections/*",
                                      element: JsxRuntime.jsx(CollectionsManagerRouter.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/search/*",
                                      element: JsxRuntime.jsx(SearchCatalogView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/users/*",
                                      element: JsxRuntime.jsx(AccountsManagerView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/user/settings/*",
                                      element: JsxRuntime.jsx(UserSettingsView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "/software/about/*",
                                      element: JsxRuntime.jsx(AboutView.make, {})
                                    }),
                                JsxRuntime.jsx(ReactRouterDom.Route, {
                                      path: "*",
                                      element: JsxRuntime.jsx(NotFoundPage.make, {})
                                    })
                              ]
                            }))
                  })
            });
}

function PhotocubaApp$ThemedApp(props) {
  var preferredColorScheme = UserPreferredColorScheme.useResolvedScheme();
  var theme = React.useMemo((function () {
          if (preferredColorScheme === "light") {
            return MuiTheme.Defaults.light;
          } else {
            return MuiTheme.Defaults.dark;
          }
        }), [preferredColorScheme]);
  return JsxRuntime.jsx(Styles.ThemeProvider, {
              theme: theme,
              children: JsxRuntime.jsx(UserContext.make, {
                    children: JsxRuntime.jsx(NamespaceContext.make, {
                          children: JsxRuntime.jsx(SharedDBContext.make, {
                                children: JsxRuntime.jsx(PhotocubaApp$PhotocubaRoutes, {})
                              }),
                          namespace: "caraibes"
                        })
                  })
            });
}

function PhotocubaApp(props) {
  React.useEffect((function () {
          Curry._2(Prelude.OptionExported.$$Option.map, Caml_option.nullable_to_opt(document.querySelector("#app.loading")), (function (el) {
                  el.removeAttribute("class");
                }));
        }), []);
  return JsxRuntime.jsx(UserPreferredColorScheme.make, {
              children: JsxRuntime.jsx(PhotocubaI18N.make, {
                    children: JsxRuntime.jsx(LocalFileDBContext.make, {
                          children: JsxRuntime.jsx(PhotocubaApp$ThemedApp, {})
                        })
                  })
            });
}

var make$1 = PhotocubaApp;

export {
  make$1 as make,
}
/* make Not a pure module */
