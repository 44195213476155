// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Group from "./Group.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "Int"
    ],
    [
      "username",
      "String"
    ],
    [
      "first_name",
      "String"
    ],
    [
      "last_name",
      "String"
    ],
    [
      "email",
      "String"
    ],
    [
      "is_active",
      "Boolean"
    ],
    [
      "is_superuser",
      "Boolean"
    ],
    [
      "groups",
      {
        TAG: "Collection",
        _0: Group.Deserializer
      }
    ],
    [
      "date_joined",
      "Date"
    ],
    [
      "last_login",
      {
        TAG: "Optional",
        _0: "Date"
      }
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function name(account) {
  var match = account.first_name;
  var match$1 = account.last_name;
  if (match === "" && match$1 === "") {
    return ;
  }
  if (match$1 === "") {
    return match;
  } else if (match === "") {
    return match$1;
  } else {
    return match + " " + match$1;
  }
}

var _map = {"any":"any","active":"active","inactive":"inactive"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function fromString(value) {
  return Prelude.default(_map[value], "any");
}

function toString(value) {
  return value;
}

var ActivationStatus = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  fromString: fromString,
  toString: toString
};

function columnToString(column) {
  return column;
}

function columnFromString(value) {
  switch (value) {
    case "date_joined" :
        return "date_joined";
    case "email" :
        return "email_normalized";
    case "last_login" :
        return "last_login";
    case "name" :
        return "name_normalized";
    case "username" :
        return "username_normalized";
    default:
      return "username_normalized";
  }
}

function directionToString(column) {
  return column;
}

function directionFromString(value) {
  if (value === "-") {
    return "-";
  } else {
    return "";
  }
}

function toggleDirection(direction) {
  if (direction === "") {
    return "-";
  } else {
    return "";
  }
}

var Order = {
  columnToString: columnToString,
  columnFromString: columnFromString,
  directionToString: directionToString,
  directionFromString: directionFromString,
  toggleDirection: toggleDirection
};

function clone(f) {
  return Object.assign({}, f);
}

var Filters_active = {
  activation_status: "active"
};

var Filters_inactive = {
  activation_status: "inactive"
};

var Filters_any = {
  activation_status: "any"
};

var Filters = {
  active: Filters_active,
  inactive: Filters_inactive,
  any: Filters_any,
  clone: clone
};

var Identifier;

export {
  Identifier ,
  Deserializer ,
  name ,
  ActivationStatus ,
  Order ,
  Filters ,
}
/* Deserializer Not a pure module */
