// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as FolderDropdown from "./affordances/FolderDropdown.js";
import * as CatalogNavigator from "../context/CatalogNavigator.js";
import * as ClipboardManager from "../context/ClipboardManager.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as ClipboardToggleButton from "./ClipboardToggleButton.js";
import Checkbox from "@mui/material/Checkbox";

function FolderCard(props) {
  var folder = props.folder;
  var getLink = CatalogNavigator.useLinkBuilder();
  var href = getLink(folder);
  var onDoubleClick = ReactRouterDom.useLinkClickHandler(href);
  var isSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useIsSelected());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useSelect());
  var unselect = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useUnselect());
  var selected = React.useMemo((function () {
          return isSelected(folder);
        }), [
        folder,
        isSelected
      ]);
  var isClipped = ClipboardManager.Contents.useIsClipped();
  var clipped = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, isClipped, (function (fn) {
                            return fn({
                                        NAME: "folder",
                                        VAL: folder
                                      });
                          })), false);
        }), [
        folder,
        isClipped
      ]);
  var elevation = React.useMemo((function () {
          if (clipped) {
            return 14;
          } else {
            return 2;
          }
        }), [clipped]);
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return React.useMemo((function () {
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Paper, {
                                  elevation: elevation,
                                  sx: {
                                    borderColor: selected ? "primary.main" : "initial",
                                    bgcolor: selected ? "action.focus" : "background.default"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                            children: [
                                              JsxRuntime.jsx(Grid, {
                                                    children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                                              checked: selected,
                                                              onChange: (function (param, selected) {
                                                                  if (selected) {
                                                                    return select([folder]);
                                                                  } else {
                                                                    return unselect([folder]);
                                                                  }
                                                                })
                                                            })),
                                                    item: true,
                                                    xs: 2
                                                  }),
                                              JsxRuntime.jsx(Grid, {
                                                    style: {
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis"
                                                    },
                                                    children: Caml_option.some(JsxRuntime.jsx("span", {
                                                              children: folder.name,
                                                              style: {
                                                                whiteSpace: "nowrap"
                                                              }
                                                            })),
                                                    item: true,
                                                    xs: 7
                                                  }),
                                              JsxRuntime.jsx(Grid, {
                                                    children: Caml_option.some(JsxRuntime.jsx(ClipboardToggleButton.make, {
                                                              item: {
                                                                NAME: "folder",
                                                                VAL: folder
                                                              },
                                                              fontSize: "small",
                                                              sx: {
                                                                display: "flex",
                                                                alignItems: "center",
                                                                alignSelf: "center",
                                                                color: "primary.main"
                                                              }
                                                            })),
                                                    item: true,
                                                    xs: 1
                                                  }),
                                              JsxRuntime.jsx(Grid, {
                                                    children: Caml_option.some(JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx(FolderDropdown.make, {
                                                                    folder: folder
                                                                  }),
                                                              onDoubleClick: (function (ev) {
                                                                  ev.stopPropagation();
                                                                })
                                                            })),
                                                    item: true,
                                                    xs: 2
                                                  })
                                            ],
                                            container: true,
                                            sx: {
                                              alignItems: "center",
                                              height: "3rem",
                                              width: "20rem",
                                              padding: "0 0.1rem"
                                            }
                                          }))
                                }),
                            className: "selectable-item",
                            id: FolderId.toString(folder.id),
                            style: style,
                            onDoubleClick: onDoubleClick
                          });
              }), [
              onDoubleClick,
              elevation,
              folder,
              unselect,
              select,
              selected,
              clipped
            ]);
}

var make = FolderCard;

export {
  make ,
}
/* react Not a pure module */
