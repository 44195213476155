// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ActionQueue from "@merchise/rescript-action-queue/lib/es6/src/ActionQueue.js";

function MakeSimpleQueueManager(T) {
  var Impl = ActionQueue.MakeActionQueue({
        name: T.name
      });
  var busy = Impl.busy;
  var length = Impl.length;
  var running = Impl.running;
  var pause = Impl.pause;
  var resume = Impl.resume;
  var paused = Impl.paused;
  var clear = Impl.clear;
  var info = Impl.info;
  var concurrency = 1 <= T.concurrency && T.concurrency < 50 ? T.concurrency : 10;
  var $$new = function () {
    return Impl.make(true, concurrency, undefined, undefined);
  };
  var queue = $$new();
  var append = function (action, identifier) {
    return Impl.append(queue, action, identifier);
  };
  var prepend = function (action, identifier) {
    return Impl.prepend(queue, action, identifier);
  };
  var clear$1 = function () {
    clear(queue);
  };
  var replace = function (action, identifier) {
    return Impl.replace(queue, action, identifier);
  };
  var pause$1 = function () {
    pause(queue);
  };
  var resume$1 = function () {
    resume(queue);
  };
  var paused$1 = function () {
    return paused(queue);
  };
  var busy$1 = function () {
    return busy(queue);
  };
  var length$1 = function () {
    return length(queue);
  };
  var running$1 = function () {
    return running(queue);
  };
  var info$1 = function () {
    return info(queue);
  };
  return {
          append: append,
          prepend: prepend,
          clear: clear$1,
          replace: replace,
          pause: pause$1,
          resume: resume$1,
          paused: paused$1,
          busy: busy$1,
          length: length$1,
          running: running$1,
          info: info$1
        };
}

export {
  MakeSimpleQueueManager ,
}
/* ActionQueue Not a pure module */
