// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Sentry from "./utils/Sentry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Hooks from "@mantine/hooks";
import * as ReactRouterDom from "react-router-dom";

function $$parseInt(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                return Curry._1(Prelude.Int.fromString, v.trim());
              }));
}

function parseBool(value) {
  return Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                var match = v.toLowerCase().trim();
                switch (match) {
                  case "true" :
                  case "yes" :
                      return true;
                  default:
                    return false;
                }
              }));
}

function parseRate(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.flatMap, value, Prelude.Float.fromString), (function (res) {
                if (0.0 <= res && res <= 1.0) {
                  return res;
                }
                
              }));
}

var releaseHash = Prelude.default(import.meta.env.PHOTOCUBA_RELEASE_HASH, "");

var _releaseDate = Prelude.default(import.meta.env.PHOTOCUBA_RELEASE_DATE, "");

var ReleaseDateDeser = $$JSON.MakeDeserializer({
      fields: "Datetime"
    });

var releaseDate = Prelude.default(Prelude.Result.ok(ReleaseDateDeser.fromJSON(_releaseDate)), new Date());

var tmp = _releaseDate === "" ? "dev" : (
    releaseHash === "" ? _releaseDate + ".Z" : _releaseDate + "." + releaseHash
  );

var releaseVersion = Prelude.default(import.meta.env.PHOTOCUBA_RELEASE_TAG, tmp);

var apiVersion = "2023";

var apiHost = Prelude.default(import.meta.env.PHOTOCUBA_API_HOST, "http://localhost:7999");

var apiBaseUrl = apiHost + "/api/" + apiVersion;

var BackendAPI = {
  apiVersion: apiVersion,
  apiHost: apiHost,
  apiBaseUrl: apiBaseUrl
};

var res = Prelude.default($$parseInt(import.meta.env.SENTRY_OFFLINE_MAX_EVENTS), 1000);

var $$default = {
  maxStoredEvents: res < 100 ? 100 : res
};

var Offline = {
  $$default: $$default
};

var default_active = Prelude.default(parseBool(import.meta.env.SENTRY_REPLAY), false);

var default_sessionRate = Prelude.default(parseRate(import.meta.env.SENTRY_REPLAY_SESSION_RATE), 0.5);

var default_errorRate = Prelude.default(parseRate(import.meta.env.SENTRY_REPLAY_ERROR_RATE), 1.0);

var $$default$1 = {
  active: default_active,
  sessionRate: default_sessionRate,
  errorRate: default_errorRate
};

var Replay = {
  $$default: $$default$1
};

var _map = {"development":"development","staging":"staging","canary":"canary","production":"production"};

function environmentFromJs(param) {
  return _map[param];
}

function settings() {
  var result_env = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, import.meta.env.SENTRY_ENVIRONMENT, environmentFromJs), "development");
  var result_release = releaseVersion;
  var result = {
    env: result_env,
    release: result_release,
    replay: $$default$1
  };
  var dsn = import.meta.env.SENTRY_DSN;
  if (dsn === undefined) {
    return result;
  }
  if (dsn === "") {
    return result;
  }
  var newrecord = Caml_obj.obj_dup(result);
  newrecord.dsn = dsn;
  return newrecord;
}

function init() {
  var match = settings();
  var dsn = match.dsn;
  if (dsn === undefined) {
    return ;
  }
  var env = match.env;
  if (env === undefined) {
    return ;
  }
  var release = match.release;
  if (release === undefined) {
    return ;
  }
  var replay = match.replay;
  var integrations = replay.active ? [
      Sentry.BrowserTracing.integration(Sentry.BrowserTracing.make({
                tracePropagationTargets: [
                  {
                    TAG: "Host",
                    _0: "localhost"
                  },
                  {
                    TAG: "Regex",
                    _0: /^https:\/\/testing\.photocuba\.com\/api/
                  },
                  {
                    TAG: "Regex",
                    _0: /^https:\/\/dev\.photocuba\.com\/api/
                  },
                  {
                    TAG: "Regex",
                    _0: /^https:\/\/www\.photocuba\.com\/api/
                  }
                ],
                routingInstrumentation: Caml_option.some(Sentry.RoutingInstrumentation.make((function (prim0, prim1) {
                            React.useEffect(Curry.__1(prim0), prim1);
                          }), ReactRouterDom.useLocation, ReactRouterDom.useNavigationType, ReactRouterDom.createRoutesFromChildren, ReactRouterDom.matchRoutes))
              })),
      Sentry.Replay.integration(Sentry.Replay.make({
                maskAllText: false
              })),
      Sentry.Offline.integration(Sentry.Offline.make($$default))
    ] : [
      Sentry.BrowserTracing.integration(Sentry.BrowserTracing.make({
                tracePropagationTargets: [
                  {
                    TAG: "Host",
                    _0: "localhost"
                  },
                  {
                    TAG: "Regex",
                    _0: /^https:\/\/dev\.photocuba\.com\/api/
                  },
                  {
                    TAG: "Regex",
                    _0: /^https:\/\/www\.photocuba\.com\/api/
                  }
                ]
              })),
      Sentry.Offline.integration(Sentry.Offline.make({
                maxStoredEvents: 300
              }))
    ];
  Sentry.init({
        dsn: dsn,
        release: release,
        environment: env,
        integrations: integrations,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: replay.sessionRate,
        replaysOnErrorSampleRate: replay.errorRate
      });
  Sentry.setTag("app.version", releaseVersion);
}

var manualLanguageSwitch = Prelude.default(import.meta.env.PHOTOCUBA_MANUAL_LANG_SWITCH, import.meta.env.PROD ? "forbid" : "allow");

var allowAppReset = Prelude.default(import.meta.env.PHOTOCUBA_USER_APP_RESET, import.meta.env.PROD ? "forbid" : "allow");

var FeatureFlags = {
  manualLanguageSwitch: manualLanguageSwitch,
  allowAppReset: allowAppReset
};

var folderItemsPerfWarningCutoff = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.flatMap, import.meta.env.PHOTOCUBA_FOLDER_ITEMS_PERF_WARNING_CUTOFF, Prelude.Int.fromString), (function (v) {
        if (v > 200) {
          return v;
        }
        
      }));

var folderItemsPerfErrorCutoff = Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.flatMap, import.meta.env.PHOTOCUBA_FOLDER_ITEMS_PERF_ERROR_CUTOFF, Prelude.Int.fromString), (function (v) {
        if (v > 500) {
          return v;
        }
        
      }));

var Catalog = {
  folderItemsPerfWarningCutoff: folderItemsPerfWarningCutoff,
  folderItemsPerfErrorCutoff: folderItemsPerfErrorCutoff
};

var maxFiles = Prelude.default($$parseInt(import.meta.env.PHOTOCUBA_UPLOAD_MAX_FILES), 300);

var maxSize = ((Prelude.default($$parseInt(import.meta.env.PHOTOCUBA_UPLOAD_MAX_SIZE), 40) << 10) << 10);

var Upload = {
  maxFiles: maxFiles,
  maxSize: maxSize
};

var maxItems = Prelude.default($$parseInt(import.meta.env.PHOTOCUBA_AUTO_COMPLETE_MAX_ITEMS), 15);

var maxItems$1 = maxItems < 10 ? 10 : (
    maxItems > 60 ? 60 : maxItems
  );

var Autocomplete = {
  maxItems: maxItems$1
};

var size = Prelude.default($$parseInt(import.meta.env.PHOTOCUBA_PAGINATION_SIZE), 15);

var defaultSize = size < 15 ? 15 : (
    size > 200 ? 200 : size
  );

function use() {
  var match = Hooks.useLocalStorage({
        key: "photocuba-tags-page-size",
        defaultValue: size,
        deserialize: (function (v) {
            return Prelude.default(Curry._1(Prelude.Int.fromString, v), size);
          }),
        serialize: (function (v) {
            return String(v < 15 ? 15 : (
                          v > 200 ? 200 : v
                        ));
          })
      });
  return [
          match[0],
          match[1]
        ];
}

var TagsManager = {
  use: use
};

function use$1() {
  var match = Hooks.useLocalStorage({
        key: "photocuba-accounts-page-size",
        defaultValue: size,
        deserialize: (function (v) {
            return Prelude.default(Curry._1(Prelude.Int.fromString, v), size);
          }),
        serialize: (function (v) {
            return String(v < 15 ? 15 : (
                          v > 200 ? 200 : v
                        ));
          })
      });
  return [
          match[0],
          match[1]
        ];
}

var AccountsManager = {
  use: use$1
};

function use$2() {
  var match = Hooks.useLocalStorage({
        key: "photocuba-collections-page-size",
        defaultValue: size,
        deserialize: (function (v) {
            return Prelude.default(Curry._1(Prelude.Int.fromString, v), size);
          }),
        serialize: (function (v) {
            return String(v < 15 ? 15 : (
                          v > 200 ? 200 : v
                        ));
          })
      });
  return [
          match[0],
          match[1]
        ];
}

var CollectionsManager = {
  use: use$2
};

function use$3() {
  var match = Hooks.useLocalStorage({
        key: "photocuba-search-page-size",
        defaultValue: size,
        deserialize: (function (v) {
            return Prelude.default(Curry._1(Prelude.Int.fromString, v), size);
          }),
        serialize: (function (v) {
            return String(v < 15 ? 15 : (
                          v > 200 ? 200 : v
                        ));
          })
      });
  return [
          match[0],
          match[1]
        ];
}

var SearchResults = {
  use: use$3
};

var url = Curry._2(Prelude.OptionExported.$$Option.map, import.meta.env.PHOTOCUBA_ADMIN_URL, (function (prim) {
        return prim.trim();
      }));

var adminURL = url !== undefined ? url : (
    import.meta.env.DEV ? apiHost + "/admin" : "/admin"
  );

init();

var Sentry$1 = {
  Offline: Offline,
  Replay: Replay,
  settings: settings,
  init: init
};

var Mode_prod = import.meta.env.PROD;

var Mode_dev = import.meta.env.DEV;

var Mode_ssr = import.meta.env.SSR;

var Mode = {
  prod: Mode_prod,
  dev: Mode_dev,
  ssr: Mode_ssr
};

var Pagination = {
  defaultSize: defaultSize,
  TagsManager: TagsManager,
  AccountsManager: AccountsManager,
  CollectionsManager: CollectionsManager,
  SearchResults: SearchResults
};

export {
  releaseDate ,
  releaseVersion ,
  releaseHash ,
  BackendAPI ,
  Sentry$1 as Sentry,
  Mode ,
  FeatureFlags ,
  Catalog ,
  Upload ,
  Autocomplete ,
  Pagination ,
  adminURL ,
}
/* releaseHash Not a pure module */
