// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as CenterBasePage from "./CenterBasePage.js";
import * as JsxRuntime from "react/jsx-runtime";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";

function FailurePage(props) {
  var __reasons = props.reasons;
  var reasons = __reasons !== undefined ? __reasons : [];
  return JsxRuntime.jsxs(CenterBasePage.make, {
              title: Prelude.default(props.title, {
                    NAME: "msg",
                    VAL: [
                      "initialization.errors.title",
                      "Initialization error"
                    ]
                  }),
              children: [
                reasons.map(function (reason, index) {
                      return JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: reason
                                      }),
                                  className: "text-lg"
                                }, String(index));
                    }),
                Prelude.default(props.children, null)
              ],
              icon: Caml_option.some(JsxRuntime.jsx(WarningAmberRounded, {
                        color: "error",
                        fontSize: "large"
                      }))
            });
}

var make = FailurePage;

export {
  make ,
}
/* ReactI18Next Not a pure module */
