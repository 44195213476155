// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

function AskNameDialog(props) {
  var __multiline = props.multiline;
  var onClose = props.onClose;
  var onChange = props.onChange;
  var name = props.name;
  var multiline = __multiline !== undefined ? __multiline : false;
  var initialName = React.useMemo((function () {
          return name;
        }), [name]);
  var match = React.useState(function () {
        return name;
      });
  var setName = match[1];
  var name$1 = match[0];
  var disabled = React.useMemo((function () {
          if (name$1 === "") {
            return true;
          } else {
            return name$1 === initialName;
          }
        }), [
        name$1,
        initialName
      ]);
  var handleTextChange = function (ev) {
    var target = ev.currentTarget;
    var value;
    try {
      value = target.value;
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      console.error(e);
      return ;
    }
    if (value === undefined) {
      return ;
    }
    var value$1 = Caml_option.valFromOption(value);
    setName(function (param) {
          return value$1;
        });
  };
  var handleRenameClick = function (param) {
    if (!disabled) {
      onChange(name$1);
      return ;
    }
    
  };
  var onKeyUp = function (ev) {
    var match = ev.key;
    if (match === "Enter" && !multiline) {
      return handleRenameClick();
    }
    
  };
  return JsxRuntime.jsxs(Dialog, {
              open: props.open_,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: props.title
                              }))
                    }),
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        props.content,
                        JsxRuntime.jsx(TextField, {
                              margin: "dense",
                              fullWidth: true,
                              InputProps: {
                                onKeyUp: onKeyUp
                              },
                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: props.fieldLabel
                                      })),
                              multiline: multiline,
                              onChange: handleTextChange,
                              value: name$1,
                              variant: "standard"
                            })
                      ],
                      sx: {
                        width: "25rem"
                      }
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "str",
                                          VAL: "Cancel"
                                        }
                                      })),
                              onClick: (function (param) {
                                  onClose();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: props.buttonLabel
                                      })),
                              onClick: handleRenameClick,
                              disabled: disabled
                            })
                      ]
                    })
              ],
              onClose: (function (param, param$1) {
                  onClose();
                })
            });
}

var make = AskNameDialog;

export {
  make ,
}
/* react Not a pure module */
