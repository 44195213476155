// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as TagId from "./TagId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function make(name) {
  return {
          id: TagId.$$null,
          name: name,
          approved: false
        };
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "Int"
    ],
    [
      "name",
      "String"
    ],
    [
      "approved",
      "Boolean"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function toURLPayload(tag) {
  return btoa(JSON.stringify(tag));
}

function fromURLPayload(payload) {
  var serial;
  try {
    serial = atob(payload);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Tag", e);
    return ;
  }
  try {
    return Prelude.Result.scream(Deserializer.fromJSON(JSON.parse(serial)));
  }
  catch (raw_e$1){
    var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e$1);
    console.error("Tag", e$1);
    return ;
  }
}

var _map = {"any":"any","approved":"approved","unapproved":"unapproved"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function fromString(value) {
  return Prelude.default(_map[value], "any");
}

function toString(value) {
  return value;
}

var ApprovalStatus = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  fromString: fromString,
  toString: toString
};

function columnToString(column) {
  return column;
}

function columnFromString(value) {
  switch (value) {
    case "approval" :
    case "approved" :
        return "approved";
    case "name" :
    case "tokenized" :
        return "tokenized";
    default:
      return "tokenized";
  }
}

function directionToString(column) {
  return column;
}

function directionFromString(value) {
  if (value === "-") {
    return "-";
  } else {
    return "";
  }
}

function toggleDirection(direction) {
  if (direction === "") {
    return "-";
  } else {
    return "";
  }
}

var Order = {
  columnToString: columnToString,
  columnFromString: columnFromString,
  directionToString: directionToString,
  directionFromString: directionFromString,
  toggleDirection: toggleDirection
};

function clone(f) {
  return Object.assign({}, f);
}

var Filters_approved = {
  approval_status: "approved"
};

var Filters_any = {
  approval_status: "any"
};

var Filters = {
  approved: Filters_approved,
  any: Filters_any,
  clone: clone
};

var Identifier;

export {
  Identifier ,
  make ,
  Deserializer ,
  toURLPayload ,
  fromURLPayload ,
  ApprovalStatus ,
  Order ,
  Filters ,
}
/* Deserializer Not a pure module */
