// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Settings from "../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../libs/i18n/ReactI18Next.js";
import * as AboutManifest from "./AboutManifest.js";
import DarkPng from "/images/dark.png";
import * as ApplicationMaker from "../global/apps/ApplicationMaker.js";
import LightPng from "/images/light.png";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import * as UserPreferredColorScheme from "../global/context/UserPreferredColorScheme.js";
import TableContainer from "@mui/material/TableContainer";

function AboutView$Main(props) {
  var scheme = UserPreferredColorScheme.useResolvedScheme();
  var imgSrc = scheme === "dark" ? DarkPng : LightPng;
  var hash = $$String.trim(Settings.releaseHash);
  var tmp = hash === "" ? null : " (" + hash + ")";
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx("img", {
                      style: {
                        display: "block",
                        margin: "1rem auto",
                        width: "50%"
                      },
                      alt: "Photo Cuba",
                      src: imgSrc
                    }),
                JsxRuntime.jsx(TableContainer, {
                      children: Caml_option.some(JsxRuntime.jsx(Table, {
                                children: Caml_option.some(JsxRuntime.jsxs(TableBody, {
                                          children: [
                                            JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsx(TableCell, {
                                                          variant: "head",
                                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                    msg: {
                                                                      NAME: "str",
                                                                      VAL: "Name"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: "Photocuba"
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsx(TableCell, {
                                                          variant: "head",
                                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                    msg: {
                                                                      NAME: "str",
                                                                      VAL: "Version"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsxs(TableCell, {
                                                          children: [
                                                            Settings.releaseVersion,
                                                            tmp
                                                          ]
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsx(TableCell, {
                                                          variant: "head",
                                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                    msg: {
                                                                      NAME: "str",
                                                                      VAL: "Date"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(Settings.releaseDate.toLocaleString())
                                                        })
                                                  ]
                                                })
                                          ]
                                        })),
                                size: "medium"
                              }))
                    })
              ],
              direction: ["column"],
              spacing: "3rem",
              sx: {
                height: "100%",
                maxWidth: {
                  xs: "90vw",
                  lg: "50vw",
                  xl: "35vw"
                },
                margin: "0 auto"
              }
            });
}

var Main = {
  make: AboutView$Main
};

var include = ApplicationMaker.MakeServiceless({
      Manifest: AboutManifest,
      Main: Main
    });

var Manifest = include.Manifest;

var Services = include.Services;

var Affordances = include.Affordances;

var Main$1 = include.Main;

var make = include.make;

export {
  Manifest ,
  Services ,
  Affordances ,
  Main$1 as Main,
  make ,
}
/* include Not a pure module */
