// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropdownMenu from "../../../../global/components/DropdownMenu.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as AskNameDialog from "../../../../global/components/AskNameDialog.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as UploadManager from "../../context/UploadManager.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as SelectionManager from "../../context/SelectionManager.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogFreeDropzone from "../CatalogFreeDropzone.js";
import * as CreateFolderService from "../../context/services/CreateFolderService.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Add from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import CreateNewFolderOutlined from "@mui/icons-material/CreateNewFolderOutlined";

function CreateNewItemAffordance$CreateFolderDialog(props) {
  var onCancel = props.onCancel;
  var onDone = props.onDone;
  var folder = props.folder;
  var match = React.useState(function () {
        return {
                TAG: "Active",
                _0: folder
              };
      });
  var setState = match[1];
  var state = match[0];
  var resetFolders = SelectionManager.Folders.useReset();
  var resetDocuments = SelectionManager.Documents.useReset();
  var create = Curry._1(Prelude.OptionExported.$$Option.getExn, CreateFolderService.useCreator());
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG === "Creating") {
            Prelude.thenDo(create(state._0, state._1), (function (res) {
                    var exit = 0;
                    if (res.TAG === "Ok" && res._0 !== undefined) {
                      Prelude.OptionExported.$$Option.$$do(resetFolders, (function (fn) {
                              fn();
                            }));
                      Prelude.OptionExported.$$Option.$$do(resetDocuments, (function (fn) {
                              fn();
                            }));
                      setState(function (param) {
                            return "Done";
                          });
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      setState(function (param) {
                            return "Error";
                          });
                    }
                    onDone();
                  }));
          }
          
        }), [state]);
  var onChange = React.useMemo((function () {
          if (typeof state === "object" && state.TAG === "Active") {
            var folder = state._0;
            return function (name) {
              setState(function (param) {
                    return {
                            TAG: "Creating",
                            _0: folder,
                            _1: name
                          };
                  });
            };
          }
          return function (_name) {
            
          };
        }), [state]);
  var tmp;
  tmp = typeof state !== "object" || state.TAG !== "Active" ? null : JsxRuntime.jsx(AskNameDialog.make, {
          name: "",
          onChange: onChange,
          onClose: (function () {
              onCancel();
              setState(function (param) {
                    return "Done";
                  });
            }),
          open_: true,
          title: {
            NAME: "str",
            VAL: "Create folder"
          },
          content: JsxRuntime.jsx(ReactI18next.Trans, {
                children: "Enter the name of the new folder"
              }),
          fieldLabel: {
            NAME: "str",
            VAL: "Folder name"
          },
          buttonLabel: {
            NAME: "str",
            VAL: "Create"
          }
        }, FolderId.toString(state._0.id));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                props.children,
                tmp
              ]
            });
}

function CreateNewItemAffordance$UploadImagesDialog(props) {
  var onDone = props.onDone;
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "upload.dialog-title",
                                    "Select files to upload"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(CatalogFreeDropzone.make, {
                                style: {
                                  border: "2px dashed #898989",
                                  cursor: "pointer",
                                  minHeight: "3rem",
                                  padding: "0.75rem"
                                },
                                childrenRender: (function (props) {
                                    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                children: [
                                                  JsxRuntime.jsx("input", props.getInputProps()),
                                                  props.isDragActive ? (
                                                      props.isDragReject ? JsxRuntime.jsx(CatalogFreeDropzone.DefaultMessages.Reject.make, {}) : JsxRuntime.jsx(CatalogFreeDropzone.DefaultMessages.Accept.make, {})
                                                    ) : JsxRuntime.jsx(CatalogFreeDropzone.DefaultMessages.Idle.make, {})
                                                ]
                                              });
                                  }),
                                onDone: onDone,
                                allowOnClick: true
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Cancel"
                                          }
                                        })),
                                onClick: (function (param) {
                                    onDone();
                                  })
                              }))
                    })
              ],
              fullWidth: true,
              maxWidth: "md",
              onClose: (function (param, param$1) {
                  onDone();
                })
            });
}

function CreateNewItemAffordance(props) {
  var children = props.children;
  var folder = props.folder;
  var canCreateFolder = CreateFolderService.useCanCreate();
  var match = React.useState(function () {
        
      });
  var setCreatorParent = match[1];
  var creatorParent = match[0];
  var upload = UploadManager.useUpload();
  var match$1 = React.useState(function () {
        return false;
      });
  var setUploadDialogOpened = match$1[1];
  var items = React.useMemo((function () {
          return [
                  {
                    TAG: "Item",
                    _0: {
                      title: {
                        NAME: "str",
                        VAL: "New folder"
                      },
                      icon: Caml_option.some(JsxRuntime.jsx(CreateNewFolderOutlined, {})),
                      action: {
                        TAG: "Click",
                        _0: (function (param) {
                            setCreatorParent(function (param) {
                                  return folder;
                                });
                          })
                      },
                      disabled: !canCreateFolder
                    }
                  },
                  {
                    TAG: "Item",
                    _0: {
                      title: {
                        NAME: "str",
                        VAL: "Upload images"
                      },
                      icon: Caml_option.some(JsxRuntime.jsx(FileUploadOutlined, {})),
                      action: {
                        TAG: "Click",
                        _0: (function (param) {
                            setUploadDialogOpened(function (param) {
                                  return true;
                                });
                          })
                      },
                      disabled: !Curry._1(Prelude.OptionExported.$$Option.isSome, upload)
                    }
                  }
                ];
        }), [folder]);
  var hidden = React.useMemo((function () {
          return Curry._2(Prelude.$$Array.every, Curry._2(Prelude.$$Array.keepMap, items, (function (item) {
                            if (typeof item !== "object") {
                              return ;
                            }
                            if (item.TAG !== "Item") {
                              return ;
                            }
                            var disabled = item._0.disabled;
                            if (disabled !== undefined) {
                              return disabled;
                            } else {
                              return false;
                            }
                          })), (function (disabled) {
                        return disabled;
                      }));
        }), [items]);
  var result = creatorParent !== undefined ? JsxRuntime.jsx(CreateNewItemAffordance$CreateFolderDialog, {
          folder: creatorParent,
          children: children,
          onDone: (function () {
              setCreatorParent(function (param) {
                    
                  });
            }),
          onCancel: (function () {
              setCreatorParent(function (param) {
                    
                  });
            })
        }) : children;
  var widget = function (key) {
    return JsxRuntime.jsx(ProtectedArea.make, {
                requirement: {
                  TAG: "Pred",
                  _0: !hidden
                },
                children: JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "El",
                        _0: JsxRuntime.jsx(Fab, {
                              children: Caml_option.some(JsxRuntime.jsx(Add, {})),
                              color: "primary",
                              size: "small"
                            })
                      },
                      items: items
                    })
              }, key);
  };
  if (match$1[0]) {
    return JsxRuntime.jsxs(ActionsManager.Register.make, {
                name: "create-folder-item",
                widget: widget,
                sequence: 0,
                children: [
                  result,
                  JsxRuntime.jsx(CreateNewItemAffordance$UploadImagesDialog, {
                        onDone: (function () {
                            setUploadDialogOpened(function (param) {
                                  return false;
                                });
                          })
                      })
                ]
              });
  } else {
    return JsxRuntime.jsx(ActionsManager.Register.make, {
                name: "create-folder-item",
                widget: widget,
                sequence: 0,
                children: result
              });
  }
}

var make = CreateNewItemAffordance;

export {
  make ,
}
/* react Not a pure module */
