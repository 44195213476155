// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentFolderManager from "../CurrentFolderManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

var NotFound = /* @__PURE__ */Caml_exceptions.create("UpdateFolderInfoService.NotFound");

function useRename() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.rename;
              }));
}

function useSetDescription() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.setDescription;
              }));
}

var canChangeFolders = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_folder"]
    });

function UpdateFolderInfoService(props) {
  CurrentFolderManager.ensureCurrentFolderManager();
  var permissions = UserContext.useUserPermissionsClaim();
  var currentFolder = CurrentFolderManager.useCurrentFolder();
  var updateCurrentFolder = CurrentFolderManager.useUpdateCurrentFolder();
  var updateSubFolder = CurrentFolderManager.useUpdateOrAddFolder();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var makeWrite = match.makeWrite;
  var allowed = React.useMemo((function () {
          return canChangeFolders(permissions);
        }), [permissions]);
  var rename = React.useMemo((function () {
          return async function (folder, name) {
            var updateFolder = Caml_obj.equal(currentFolder, folder) ? updateCurrentFolder : updateSubFolder;
            var previousName = folder.name;
            var newrecord = Caml_obj.obj_dup(folder);
            updateFolder((newrecord.name = name, newrecord));
            var e = await CatalogAPIEndpoint.UpdateFolder.rename(folder, name, token);
            var result;
            if (e.TAG === "Ok") {
              var f = e._0;
              if (f !== undefined) {
                updateFolder(f);
                result = {
                  TAG: "Ok",
                  _0: f
                };
              } else {
                result = await Prelude.rejectWithError({
                      RE_EXN_ID: NotFound,
                      code: 404,
                      message: "Not found"
                    });
              }
            } else {
              var newrecord$1 = Caml_obj.obj_dup(folder);
              updateFolder((newrecord$1.name = previousName, newrecord$1));
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var folder$1 = result._0;
            var init = makeWrite();
            await write({
                  documents: init.documents,
                  folders: [{
                      TAG: "Save",
                      _0: folder$1
                    }],
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: folder$1
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        updateCurrentFolder,
        currentFolder,
        updateSubFolder
      ]);
  var setDescription = React.useMemo((function () {
          return async function (folder, description) {
            var updateFolder = Caml_obj.equal(currentFolder, folder) ? updateCurrentFolder : updateSubFolder;
            var previous = folder.description;
            var newrecord = Caml_obj.obj_dup(folder);
            updateFolder((newrecord.description = description, newrecord));
            var e = await CatalogAPIEndpoint.UpdateFolder.setDescription(folder, description, token);
            var result;
            if (e.TAG === "Ok") {
              var f = e._0;
              if (f !== undefined) {
                updateFolder(f);
                result = {
                  TAG: "Ok",
                  _0: f
                };
              } else {
                result = await Prelude.rejectWithError({
                      RE_EXN_ID: NotFound,
                      code: 404,
                      message: "Not found"
                    });
              }
            } else {
              var newrecord$1 = Caml_obj.obj_dup(folder);
              updateFolder((newrecord$1.description = previous, newrecord$1));
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var folder$1 = result._0;
            var init = makeWrite();
            await write({
                  documents: init.documents,
                  folders: [{
                      TAG: "Save",
                      _0: folder$1
                    }],
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: folder$1
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        updateCurrentFolder,
        currentFolder,
        updateSubFolder
      ]);
  var value = React.useMemo((function () {
          if (allowed) {
            return {
                    rename: rename,
                    setDescription: setDescription
                  };
          }
          
        }), [
        allowed,
        rename,
        setDescription
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = UpdateFolderInfoService;

export {
  useRename ,
  useSetDescription ,
  make$1 as make,
}
/* context Not a pure module */
