// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as UUID7 from "./UUID7.js";
import * as Utils from "./Utils.js";
import * as Network from "./Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

var UUID = Utils.MakeOpaqueIdentifier({});

function migrations() {
  return [
          (function (param) {
              return function (db, transaction) {
                IDB__Migration__Database.createObjectStore(db, "metadatas");
                IDB__Migration__Database.createObjectStore(db, "resources");
                transaction.objectStore("metadatas").createIndex("file", "file");
                transaction.objectStore("resources").createIndex("url", "url");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                transaction.objectStore("metadatas").createIndex("access_time", "access_time");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            })
        ];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

var MetadataDef = {};

ReIndexed.MakeModel(MetadataDef);

var ResourceDef = {};

ReIndexed.MakeModel(ResourceDef);

function makeRead() {
  return {
          metadatas: "NoOp",
          resources: "NoOp"
        };
}

function makeWrite() {
  return {
          metadatas: [],
          resources: []
        };
}

function makeResponse() {
  return {
          metadatas: [],
          resources: []
        };
}

var QueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var Query = Curry._1(Database.MakeQuery, QueryDef);

function deleteMany(ids) {
  console.log("LocalFile: DELETE", ids);
  return Query.write({
              metadatas: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: UUID.toString(id)
                          };
                  }),
              resources: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: UUID.toString(id)
                          };
                  })
            });
}

async function allIds() {
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: "All",
        resources: init.resources
      });
  return match.metadatas.map(function (meta) {
              return meta.id;
            });
}

async function clean(sureOpt, nonlocalOpt, param) {
  var sure = sureOpt !== undefined ? sureOpt : false;
  var nonlocal = nonlocalOpt !== undefined ? nonlocalOpt : true;
  if (!sure) {
    return ;
  }
  var getIds;
  if (nonlocal) {
    var init = Query.makeRead();
    var match = await Query.read({
          metadatas: {
            TAG: "NotNull",
            _0: "file"
          },
          resources: init.resources
        });
    getIds = match.metadatas.map(function (meta) {
          return meta.id;
        });
  } else {
    getIds = await allIds();
  }
  await deleteMany(getIds);
}

async function getMetadata(id) {
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: UUID.toString(id)
        },
        resources: init.resources
      });
  var metadatas = match.metadatas;
  var access_time = new Date();
  var init$1 = Query.makeWrite();
  await Query.write({
        metadatas: metadatas.map(function (m) {
              var newrecord = Caml_obj.obj_dup(m);
              return {
                      TAG: "Save",
                      _0: (newrecord.access_time = Caml_option.some(access_time), newrecord)
                    };
            }),
        resources: init$1.resources
      });
  return Prelude.$$Array.first(metadatas);
}

async function getWithMetadata(id) {
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: UUID.toString(id)
        },
        resources: {
          TAG: "Get",
          _0: UUID.toString(id)
        }
      });
  return [
          Prelude.$$Array.first(match.metadatas),
          Prelude.$$Array.first(match.resources)
        ];
}

async function getResource(id) {
  var match = await getWithMetadata(id);
  var resource = match[1];
  var metadata = match[0];
  if (resource !== undefined && metadata !== undefined) {
    var init = Query.makeWrite();
    var newrecord = Caml_obj.obj_dup(metadata);
    await Query.write({
          metadatas: [{
              TAG: "Save",
              _0: (newrecord.access_time = Caml_option.some(new Date()), newrecord)
            }],
          resources: init.resources
        });
  }
  return resource;
}

async function _storeWithKey(key, blob, compressed, file) {
  var file$1 = Curry._2(Prelude.OptionExported.$$Option.map, file, $$URL.Utils.withoutSearch);
  console.log("LocalFile: STORE", key, {
        size: blob.size,
        file: file$1,
        compressed: compressed
      });
  var resource = {
    id: key,
    file: blob
  };
  var access_time = new Date();
  var match = await Query.write({
        metadatas: [{
            TAG: "Save",
            _0: {
              id: key,
              size: blob.size,
              compressed: compressed,
              file: file$1,
              access_time: Caml_option.some(access_time)
            }
          }],
        resources: [{
            TAG: "Save",
            _0: resource
          }]
      });
  var metadatas = match.metadatas;
  if (metadatas.length === 1) {
    var metadata = metadatas[0];
    var $$window$1 = window;
    var $$event = new Event("local-file:store", {
          id: metadata.id,
          size: metadata.size,
          compressed: metadata.compressed,
          file: metadata.file
        });
    $$window$1.dispatchEvent($$event);
  }
  return resource;
}

function store(blob) {
  return _storeWithKey(UUID.fromString(UUID7.make()), blob, false, undefined);
}

function storeCompressed(blob) {
  return _storeWithKey(UUID.fromString(UUID7.make()), blob, true, undefined);
}

function save(key, blob) {
  return _storeWithKey(key, blob, false, undefined);
}

function saveCompressed(key, blob) {
  return _storeWithKey(key, blob, true, undefined);
}

function clone(key) {
  var access_time = new Date();
  return Prelude.then(getWithMetadata(key), (function (param) {
                var resource = param[1];
                var metadata = param[0];
                if (metadata === undefined) {
                  return Promise.resolve(undefined);
                }
                if (resource === undefined) {
                  return Promise.resolve(undefined);
                }
                var id = UUID.make();
                var newrecord = Caml_obj.obj_dup(metadata);
                newrecord.access_time = Caml_option.some(access_time);
                newrecord.id = id;
                var resource_file = resource.file;
                var resource$1 = {
                  id: id,
                  file: resource_file
                };
                return Prelude.thenResolve(Query.write({
                                metadatas: [{
                                    TAG: "Save",
                                    _0: newrecord
                                  }],
                                resources: [{
                                    TAG: "Save",
                                    _0: resource$1
                                  }]
                              }), (function (param) {
                              return Caml_option.some(id);
                            }));
              }));
}

async function updateFileURL(id, file) {
  var url = $$URL.Utils.withoutSearch(file);
  console.log("LocalFile: Updated URL", id, "->", url);
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: UUID.toString(id)
        },
        resources: init.resources
      });
  var metadatas = match.metadatas;
  var access_time = new Date();
  if (metadatas.length !== 1) {
    return ;
  }
  var metadata = metadatas[0];
  var init$1 = Query.makeWrite();
  var newrecord = Caml_obj.obj_dup(metadata);
  await Query.write({
        metadatas: [{
            TAG: "Save",
            _0: (newrecord.access_time = Caml_option.some(access_time), newrecord.file = Caml_option.some(file), newrecord)
          }],
        resources: init$1.resources
      });
  return Caml_option.some(id);
}

async function downloadRequest(id, request, compressed) {
  var url = $$URL.Utils.withoutSearch(request.url);
  var found = await getResource(id);
  if (found !== undefined) {
    return found;
  }
  var payload = await Prelude.PromisedResult.warn(Network.downloadRequest(request));
  if (payload !== undefined) {
    return await _storeWithKey(id, Caml_option.valFromOption(payload), compressed, Caml_option.some(url));
  }
  
}

function download(id, url, compressed) {
  return downloadRequest(id, Network.$$Request.make(url), compressed);
}

async function compressWith(key, fn) {
  var match = await getWithMetadata(key);
  var resource = match[1];
  var meta = match[0];
  if (resource === undefined) {
    return ;
  }
  if (meta !== undefined) {
    if (meta.compressed) {
      return resource;
    }
    var blob = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
            return prim;
          }));
    await _storeWithKey(key, blob, true, meta.file);
    return {
            id: key,
            file: blob
          };
  }
  var blob$1 = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
          return prim;
        }));
  await _storeWithKey(key, blob$1, true, undefined);
  return {
          id: key,
          file: blob$1
        };
}

var Database_disconnect = Database.disconnect;

var Database_drop = Database.drop;

var Database_connect = Database.connect;

var Database$1 = {
  disconnect: Database_disconnect,
  drop: Database_drop,
  connect: Database_connect
};

var Metadata = {};

var Resource = {};

var Query$1 = {};

export {
  UUID ,
  Database$1 as Database,
  MetadataDef ,
  Metadata ,
  ResourceDef ,
  Resource ,
  Query$1 as Query,
  deleteMany ,
  allIds ,
  clean ,
  getResource ,
  getMetadata ,
  store ,
  storeCompressed ,
  save ,
  saveCompressed ,
  updateFileURL ,
  downloadRequest ,
  download ,
  compressWith ,
  clone ,
}
/* UUID Not a pure module */
