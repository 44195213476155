// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../../Settings.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogBrowserSortContext from "./CatalogBrowserSortContext.js";

var default_documents = [];

var default_pageSize = Settings.Pagination.defaultSize;

var $$default = {
  documents: default_documents,
  active: undefined,
  count: 0,
  page: 1,
  pageSize: default_pageSize
};

var context = React.createContext({
      TAG: "Ready",
      _0: $$default
    });

var make = context.Provider;

function dispatch(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "BeginTransaction" :
          if (state.TAG === "Ready") {
            var previous = state._0;
            return {
                    TAG: "Transaction",
                    previous: previous,
                    working: {
                      documents: Curry._2(Prelude.$$Array.concat, previous.documents, []),
                      active: previous.active,
                      count: previous.count,
                      page: previous.page,
                      pageSize: previous.pageSize
                    }
                  };
          }
          console.error("CurrentDocumentListManager", "Unexpected BeginTransaction, while already inside one");
          return state;
      case "Commit" :
          if (state.TAG !== "Ready") {
            return {
                    TAG: "Ready",
                    _0: state.working
                  };
          }
          console.error("CurrentDocumentListManager", "Unexpected Commit outside a transaction");
          return state;
      case "Rollback" :
          if (state.TAG !== "Ready") {
            return {
                    TAG: "Ready",
                    _0: state.previous
                  };
          }
          console.error("CurrentDocumentListManager", "Unexpected Rollback outside a transaction");
          return state;
      case "ClearActiveDocument" :
          if (state.TAG === "Ready") {
            var working = state._0;
            return {
                    TAG: "Ready",
                    _0: {
                      documents: working.documents,
                      active: undefined,
                      count: working.count,
                      page: working.page,
                      pageSize: working.pageSize
                    }
                  };
          }
          var working$1 = state.working;
          return {
                  TAG: "Transaction",
                  previous: state.previous,
                  working: {
                    documents: working$1.documents,
                    active: undefined,
                    count: working$1.count,
                    page: working$1.page,
                    pageSize: working$1.pageSize
                  }
                };
      
    }
  } else {
    switch (action.TAG) {
      case "Reset" :
          return {
                  TAG: "Ready",
                  _0: action._0
                };
      case "UpdateOrAddDocument" :
          var $$document = action._0;
          var working$2;
          var exit = 0;
          var working$3;
          if (state.TAG === "Ready") {
            working$3 = state._0;
            exit = 1;
          } else {
            working$3 = state.working;
            exit = 1;
          }
          if (exit === 1) {
            var count = working$3.count;
            var replaced = {
              contents: false
            };
            var documents = Curry._2(Prelude.$$Array.keepMap, working$3.documents, (function (f) {
                    if (Caml_obj.notequal(f.id, $$document.id)) {
                      return f;
                    } else {
                      replaced.contents = true;
                      return $$document;
                    }
                  }));
            var documents$1 = replaced.contents ? documents : Prelude.$$Array.append(documents, $$document);
            var count$1 = replaced.contents ? count : count + 1 | 0;
            var active = Curry._2(Prelude.OptionExported.$$Option.flatMap, working$3.active, (function (d) {
                    if (Caml_obj.equal(d.id, $$document.id)) {
                      return $$document;
                    }
                    
                  }));
            console.log("CurrentDocumentListManager", "updateOrAddDocument", {
                  document: $$document,
                  documents: documents$1,
                  active: active
                });
            working$2 = {
              documents: documents$1,
              active: active,
              count: count$1,
              page: working$3.page,
              pageSize: working$3.pageSize
            };
          }
          if (state.TAG === "Ready") {
            return {
                    TAG: "Ready",
                    _0: working$2
                  };
          } else {
            return {
                    TAG: "Transaction",
                    previous: state.previous,
                    working: working$2
                  };
          }
      case "UpdateDocument" :
          var $$document$1 = action._0;
          var working$4;
          var exit$1 = 0;
          var working$5;
          if (state.TAG === "Ready") {
            working$5 = state._0;
            exit$1 = 1;
          } else {
            working$5 = state.working;
            exit$1 = 1;
          }
          if (exit$1 === 1) {
            var documents$2 = Curry._2(Prelude.$$Array.keepMap, working$5.documents, (function (d) {
                    if (Caml_obj.notequal(d.id, $$document$1.id)) {
                      return d;
                    } else {
                      return $$document$1;
                    }
                  }));
            var active$1 = Curry._2(Prelude.OptionExported.$$Option.flatMap, working$5.active, (function (d) {
                    if (Caml_obj.equal(d.id, $$document$1.id)) {
                      return $$document$1;
                    }
                    
                  }));
            console.log("CurrentDocumentListManager", "updateDocument", {
                  document: $$document$1,
                  documents: documents$2,
                  active: active$1
                });
            working$4 = {
              documents: documents$2,
              active: active$1,
              count: working$5.count,
              page: working$5.page,
              pageSize: working$5.pageSize
            };
          }
          if (state.TAG === "Ready") {
            return {
                    TAG: "Ready",
                    _0: working$4
                  };
          } else {
            return {
                    TAG: "Transaction",
                    previous: state.previous,
                    working: working$4
                  };
          }
      case "RemoveDocument" :
          var $$document$2 = action._0;
          var working$6;
          var exit$2 = 0;
          var working$7;
          if (state.TAG === "Ready") {
            working$7 = state._0;
            exit$2 = 1;
          } else {
            working$7 = state.working;
            exit$2 = 1;
          }
          if (exit$2 === 1) {
            var count$2 = working$7.count;
            var documents$3 = working$7.documents;
            var previousCount = documents$3.length;
            var documents$4 = Curry._2(Prelude.$$Array.keepMap, documents$3, (function (f) {
                    if (Caml_obj.notequal(f.id, $$document$2.id)) {
                      return f;
                    }
                    
                  }));
            var currentCount = documents$4.length;
            var count$3 = previousCount !== currentCount ? count$2 - 1 | 0 : count$2;
            var active$2 = Curry._2(Prelude.OptionExported.$$Option.flatMap, working$7.active, (function (d) {
                    if (Caml_obj.equal(d.id, $$document$2.id)) {
                      return ;
                    } else {
                      return d;
                    }
                  }));
            console.log("CurrentDocumentListManager", "removeDocument", {
                  document: $$document$2,
                  documents: documents$4,
                  active: active$2
                });
            working$6 = {
              documents: documents$4,
              active: active$2,
              count: count$3,
              page: working$7.page,
              pageSize: working$7.pageSize
            };
          }
          if (state.TAG === "Ready") {
            return {
                    TAG: "Ready",
                    _0: working$6
                  };
          } else {
            return {
                    TAG: "Transaction",
                    previous: state.previous,
                    working: working$6
                  };
          }
      case "SetActiveDocument" :
          var $$document$3 = action._0;
          var working$8;
          var exit$3 = 0;
          var working$9;
          if (state.TAG === "Ready") {
            working$9 = state._0;
            exit$3 = 1;
          } else {
            working$9 = state.working;
            exit$3 = 1;
          }
          if (exit$3 === 1) {
            var documents$5 = working$9.documents;
            var active$3 = documents$5.find(function (d) {
                  return Caml_obj.equal(d.id, $$document$3.id);
                });
            console.log("CurrentDocumentListManager", "setActiveDocument", {
                  documents: documents$5,
                  active: active$3
                });
            working$8 = {
              documents: working$9.documents,
              active: active$3,
              count: working$9.count,
              page: working$9.page,
              pageSize: working$9.pageSize
            };
          }
          if (state.TAG === "Ready") {
            return {
                    TAG: "Ready",
                    _0: working$8
                  };
          } else {
            return {
                    TAG: "Transaction",
                    previous: state.previous,
                    working: working$8
                  };
          }
      case "SetPage" :
          var page = action._0;
          if (page >= 1) {
            if (state.TAG === "Ready") {
              var working$10 = state._0;
              return {
                      TAG: "Ready",
                      _0: {
                        documents: working$10.documents,
                        active: working$10.active,
                        count: working$10.count,
                        page: page,
                        pageSize: working$10.pageSize
                      }
                    };
            }
            var working$11 = state.working;
            return {
                    TAG: "Transaction",
                    previous: state.previous,
                    working: {
                      documents: working$11.documents,
                      active: working$11.active,
                      count: working$11.count,
                      page: page,
                      pageSize: working$11.pageSize
                    }
                  };
          }
          console.error("CurrentDocumentListManager", "Invalid page number " + String(page));
          return state;
      case "SetPageSize" :
          var pageSize = action._0;
          if (state.TAG === "Ready") {
            var working$12 = state._0;
            return {
                    TAG: "Ready",
                    _0: {
                      documents: working$12.documents,
                      active: working$12.active,
                      count: working$12.count,
                      page: working$12.page,
                      pageSize: pageSize
                    }
                  };
          }
          var working$13 = state.working;
          return {
                  TAG: "Transaction",
                  previous: state.previous,
                  working: {
                    documents: working$13.documents,
                    active: working$13.active,
                    count: working$13.count,
                    page: working$13.page,
                    pageSize: pageSize
                  }
                };
      
    }
  }
}

function useDocuments() {
  var match = React.useContext(context);
  var documents;
  documents = match.TAG === "Ready" ? match._0.documents : match.previous.documents;
  var sortDocuments = CatalogBrowserSortContext.$$Document.useSort();
  return sortDocuments(documents);
}

function useCount() {
  var match = React.useContext(context);
  if (match.TAG === "Ready") {
    return match._0.count;
  } else {
    return match.previous.count;
  }
}

function useActiveDocument() {
  var match = React.useContext(context);
  if (match.TAG === "Ready") {
    return match._0.active;
  } else {
    return match.previous.active;
  }
}

function usePage() {
  var match = React.useContext(context);
  if (match.TAG === "Ready") {
    return match._0.page;
  } else {
    return match.previous.page;
  }
}

function usePageSize() {
  var match = React.useContext(context);
  if (match.TAG === "Ready") {
    return match._0.pageSize;
  } else {
    return match.previous.pageSize;
  }
}

var context$1 = React.createContext({
      updateOrAddDocument: (function (param) {
          
        }),
      updateDocument: (function (param) {
          
        }),
      removeDocument: (function (param) {
          
        }),
      setActiveDocument: (function (param) {
          
        }),
      clearActiveDocument: (function () {
          
        }),
      setPage: (function (param) {
          
        }),
      setPageSize: (function (param) {
          
        }),
      reset: (function (param) {
          
        }),
      begin: (function () {
          
        }),
      commit: (function () {
          
        }),
      rollback: (function () {
          
        })
    });

var make$1 = context$1.Provider;

function useUpdateOrAddDocument() {
  return React.useContext(context$1).updateOrAddDocument;
}

function useUpdateDocument() {
  return React.useContext(context$1).updateDocument;
}

function useRemoveDocument() {
  return React.useContext(context$1).removeDocument;
}

function useSetActiveDocument() {
  return React.useContext(context$1).setActiveDocument;
}

function useClearActiveDocument() {
  return React.useContext(context$1).clearActiveDocument;
}

function useSetPage() {
  return React.useContext(context$1).setPage;
}

function useSetPageSize() {
  return React.useContext(context$1).setPageSize;
}

function useReset() {
  return React.useContext(context$1).reset;
}

function useBeginTransaction() {
  return React.useContext(context$1).begin;
}

function useCommit() {
  return React.useContext(context$1).commit;
}

function useRollback() {
  return React.useContext(context$1).rollback;
}

function CurrentDocumentListManager(props) {
  var match = React.useReducer(dispatch, {
        TAG: "Ready",
        _0: $$default
      });
  var dispatch$1 = match[1];
  var updateOrAddDocument = function ($$document) {
    dispatch$1({
          TAG: "UpdateOrAddDocument",
          _0: $$document
        });
  };
  var updateDocument = function ($$document) {
    dispatch$1({
          TAG: "UpdateDocument",
          _0: $$document
        });
  };
  var removeDocument = function ($$document) {
    dispatch$1({
          TAG: "RemoveDocument",
          _0: $$document
        });
  };
  var setActiveDocument = function ($$document) {
    dispatch$1({
          TAG: "SetActiveDocument",
          _0: $$document
        });
  };
  var clearActiveDocument = function () {
    dispatch$1("ClearActiveDocument");
  };
  var setPage = function (page) {
    dispatch$1({
          TAG: "SetPage",
          _0: page
        });
  };
  var setPageSize = function (size) {
    dispatch$1({
          TAG: "SetPageSize",
          _0: size
        });
  };
  var reset = function (state) {
    dispatch$1({
          TAG: "Reset",
          _0: state
        });
  };
  var begin = function () {
    dispatch$1("BeginTransaction");
  };
  var commit = function () {
    dispatch$1("Commit");
  };
  var rollback = function () {
    dispatch$1("Rollback");
  };
  return JsxRuntime.jsx(make, {
              value: match[0],
              children: JsxRuntime.jsx(make$1, {
                    value: {
                      updateOrAddDocument: updateOrAddDocument,
                      updateDocument: updateDocument,
                      removeDocument: removeDocument,
                      setActiveDocument: setActiveDocument,
                      clearActiveDocument: clearActiveDocument,
                      setPage: setPage,
                      setPageSize: setPageSize,
                      reset: reset,
                      begin: begin,
                      commit: commit,
                      rollback: rollback
                    },
                    children: props.children
                  })
            });
}

var Context = {};

var make$2 = CurrentDocumentListManager;

export {
  Context ,
  useDocuments ,
  useCount ,
  useActiveDocument ,
  usePage ,
  usePageSize ,
  useUpdateOrAddDocument ,
  useUpdateDocument ,
  useRemoveDocument ,
  useSetActiveDocument ,
  useClearActiveDocument ,
  useReset ,
  useSetPage ,
  useSetPageSize ,
  useBeginTransaction ,
  useCommit ,
  useRollback ,
  make$2 as make,
}
/* context Not a pure module */
