// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Hooks from "@mantine/hooks";
import * as JsxRuntime from "react/jsx-runtime";
import UseMediaQuery from "@mui/material/useMediaQuery";

var _map = {"dark":"dark","light":"light","system":"system"};

var context = React.createContext({
      scheme: "light",
      setScheme: (function (param) {
          
        })
    });

var make = context.Provider;

function usePreferredScheme() {
  return React.useContext(context).scheme;
}

function useSystemScheme() {
  var systemDark = UseMediaQuery("(prefers-color-scheme: dark)");
  if (systemDark) {
    return "dark";
  } else {
    return "light";
  }
}

function useResolvedScheme() {
  var system = useSystemScheme();
  var scheme = usePreferredScheme();
  if (scheme === "system") {
    return system;
  } else if (scheme === "light") {
    return "light";
  } else {
    return "dark";
  }
}

function useSetPreferredScheme() {
  return React.useContext(context).setScheme;
}

function UserPreferredColorScheme(props) {
  var match = Hooks.useLocalStorage({
        key: "photocuba-color-scheme",
        defaultValue: "light",
        deserialize: (function (v) {
            return Prelude.default(_map[v], "light");
          }),
        serialize: (function (v) {
            return v;
          })
      });
  return JsxRuntime.jsx(make, {
              value: {
                scheme: match[0],
                setScheme: match[1]
              },
              children: props.children
            });
}

var make$1 = UserPreferredColorScheme;

export {
  usePreferredScheme ,
  useResolvedScheme ,
  useSystemScheme ,
  useSetPreferredScheme ,
  make$1 as make,
}
/* context Not a pure module */
