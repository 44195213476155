// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FailurePage from "../components/pages/FailurePage.js";
import * as UserContext from "./UserContext.js";
import * as LogoutButton from "../components/LogoutButton.js";
import * as ReloadButton from "../components/ReloadButton.js";
import * as FlexGrowSpacer from "../components/FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NamespacesAPIEndpoint from "../api/NamespacesAPIEndpoint.js";
import Backdrop from "@mui/material/Backdrop";

var context = React.createContext(undefined);

var make = context.Provider;

function useNamespace() {
  return Curry._1(Prelude.OptionExported.$$Option.getExn, React.useContext(context));
}

function NamespaceContext(props) {
  var namespace = Prelude.default(props.namespace, "caraibes");
  var user = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useUserClaim());
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: user
              };
      });
  var setState = match[1];
  var state = match[0];
  var token = UserContext.useAccessToken();
  var $$fetch = React.useCallback((function () {
          return NamespacesAPIEndpoint.getAll(undefined, token);
        }), [token]);
  React.useEffect((function () {
          var exit = 0;
          switch (state.TAG) {
            case "Init" :
                Prelude.PromisedResult.mapError(Prelude.PromisedResult.map($$fetch(), (function (nss) {
                            var nss$1 = Curry._2(Prelude.$$Array.keep, nss, (function (param) {
                                    return param.name === namespace;
                                  }));
                            if (nss$1.length !== 1) {
                              return setState(function (param) {
                                          return {
                                                  TAG: "NotFound",
                                                  _0: user
                                                };
                                        });
                            }
                            var ns = nss$1[0];
                            setState(function (param) {
                                  return {
                                          TAG: "Loaded",
                                          _0: user,
                                          _1: ns
                                        };
                                });
                          })), (function (param) {
                        setState(function (param) {
                              return {
                                      TAG: "NotFound",
                                      _0: user
                                    };
                            });
                      }));
                break;
            case "Loaded" :
            case "NotFound" :
                exit = 1;
                break;
            
          }
          if (exit === 1) {
            var u = state._0;
            if (Caml_obj.notequal(u, user)) {
              setState(function (param) {
                    return {
                            TAG: "Init",
                            _0: u
                          };
                  });
            }
            
          }
          
        }), [user]);
  switch (state.TAG) {
    case "Init" :
        return JsxRuntime.jsx(Backdrop, {
                    open: true
                  });
    case "Loaded" :
        return JsxRuntime.jsx(make, {
                    value: state._1,
                    children: props.children
                  });
    case "NotFound" :
        var reasons = [{
            NAME: "msg",
            VAL: [
              "500.description",
              "There was a server error while loading.  Please, wait and retry."
            ]
          }];
        return JsxRuntime.jsxs(FailurePage.make, {
                    title: {
                      NAME: "str",
                      VAL: "Server error"
                    },
                    reasons: reasons,
                    children: [
                      JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                      JsxRuntime.jsx(ReloadButton.make, {}),
                      JsxRuntime.jsx(LogoutButton.make, {})
                    ]
                  });
    
  }
}

var make$1 = NamespaceContext;

export {
  useNamespace ,
  make$1 as make,
}
/* context Not a pure module */
