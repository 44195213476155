// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Utils from "../../utils/Utils.js";

var _map = {"catalog.allow_full_download":"catalog.allow_full_download","catalog.browse_trash":"catalog.browse_trash","catalog.change_attribute":"catalog.change_attribute","catalog.change_bundle":"catalog.change_bundle","catalog.change_bundler":"catalog.change_bundler","catalog.change_collection":"catalog.change_collection","catalog.change_document":"catalog.change_document","catalog.change_folder":"catalog.change_folder","catalog.change_namespace":"catalog.change_namespace","catalog.view_attribute":"catalog.view_attribute","catalog.view_bundle":"catalog.view_bundle","catalog.view_bundler":"catalog.view_bundler","catalog.view_collection":"catalog.view_collection","catalog.view_document":"catalog.view_document","catalog.view_folder":"catalog.view_folder","catalog.view_namespace":"catalog.view_namespace","tagging.change_tag":"tagging.change_tag","auth.add_user":"auth.add_user","auth.change_user":"auth.change_user","auth.view_user":"auth.view_user","auth.delete_user":"auth.delete_user","__future__.nobody":"__future__.nobody"};

function fromJS(x) {
  return _map[x];
}

var $$Set = Utils.MakeStringTypeSet({});

var fields = $$JSON.Field.variadicString("permission", (function (x) {
        return fromJS(x);
      }));

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function materialize(query) {
  if (typeof query !== "object") {
    return "MaterializedPublic";
  }
  switch (query.TAG) {
    case "All" :
        return {
                TAG: "AllStrings",
                _0: $$Set.fromArray(query._0)
              };
    case "Any" :
        return {
                TAG: "AnyStrings",
                _0: $$Set.fromArray(query._0)
              };
    case "And" :
        return {
                TAG: "Both",
                _0: materialize(query._0),
                _1: materialize(query._1)
              };
    case "Or" :
        return {
                TAG: "EitherOrBoth",
                _0: materialize(query._0),
                _1: materialize(query._1)
              };
    
  }
}

function execute(_materialized, perms) {
  while(true) {
    var materialized = _materialized;
    if (typeof materialized !== "object") {
      return true;
    }
    switch (materialized.TAG) {
      case "AllStrings" :
          return $$Set.subset(materialized._0, perms);
      case "AnyStrings" :
          return !$$Set.isEmpty($$Set.intersect(materialized._0, perms));
      case "Both" :
          if (!execute(materialized._0, perms)) {
            return false;
          }
          _materialized = materialized._1;
          continue ;
      case "EitherOrBoth" :
          if (execute(materialized._0, perms)) {
            return true;
          }
          _materialized = materialized._1;
          continue ;
      
    }
  };
}

function compile(query) {
  var materialized = materialize(query);
  return function (permissions) {
    var perms = $$Set.fromArray(permissions);
    return execute(materialized, perms);
  };
}

function matches(query, permissions) {
  var compiled = compile(query);
  return compiled(permissions);
}

var Query = {
  compile: compile,
  matches: matches
};

export {
  fromJS ,
  $$Set ,
  Deserializer ,
  Query ,
}
/* Set Not a pure module */
