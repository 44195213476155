// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as DropdownMenu from "../../../../global/components/DropdownMenu.js";
import * as BundlesManager from "../../context/BundlesManager.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as ClipboardManager from "../../context/ClipboardManager.js";
import * as DeleteItemDialog from "./DeleteItemDialog.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RenameFolderDialog from "./RenameFolderDialog.js";
import * as UpdateFolderInfoService from "../../context/services/UpdateFolderInfoService.js";
import * as CatalogDeleteVariantContext from "../../context/CatalogDeleteVariantContext.js";
import MoreVert from "@mui/icons-material/MoreVert";
import ContentCut from "@mui/icons-material/ContentCut";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";

function FolderDropdown(props) {
  var folder = props.folder;
  var permissions = UserContext.useUserPermissionsClaim();
  var manager = {
    TAG: "Any",
    _0: ["catalog.change_folder"]
  };
  var renamer = UpdateFolderInfoService.useRename();
  var deleteVariant = CatalogDeleteVariantContext.use();
  var match = React.useState(function () {
        
      });
  var setRenameFolder = match[1];
  var renameFolder = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteFolder = match$1[1];
  var deleteFolder = match$1[0];
  var requestBundle = BundlesManager.useRequest();
  var items = [{
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Download"
        },
        icon: Caml_option.some(JsxRuntime.jsx(CloudDownloadOutlined, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              Prelude.OptionExported.$$Option.$$do(requestBundle, (function (fn) {
                      fn([folder.id], []);
                    }));
            })
        },
        disabled: !Curry._1(Prelude.OptionExported.$$Option.isSome, requestBundle)
      }
    }];
  var hasClipboard = ClipboardManager.Contents.isActive();
  var alreadyInClipboard = ClipboardManager.Contents.useIsClipped();
  var alreadyInClipboard$1 = React.useMemo((function () {
          return Prelude.default(alreadyInClipboard, (function (param) {
                        return false;
                      }));
        }), [alreadyInClipboard]);
  var putInClipboard = ClipboardManager.Contents.usePut();
  var putInClipboard$1 = React.useMemo((function () {
          return Prelude.default(putInClipboard, (function (param) {
                        
                      }));
        }), [putInClipboard]);
  var items$1;
  if (UserPermission.Query.matches(manager, permissions)) {
    var tmp;
    switch (deleteVariant) {
      case "MoveToTrash" :
          tmp = {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Trash"
              },
              icon: Caml_option.some(JsxRuntime.jsx(DeleteOutlined, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    setDeleteFolder(function (param) {
                          return folder;
                        });
                  })
              }
            }
          };
          break;
      case "RemoveForever" :
          tmp = {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Remove"
              },
              icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    setDeleteFolder(function (param) {
                          return folder;
                        });
                  })
              }
            }
          };
          break;
      case "Disallowed" :
          tmp = {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Remove"
              },
              icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    setDeleteFolder(function (param) {
                          
                        });
                  })
              },
              disabled: true
            }
          };
          break;
      
    }
    items$1 = Curry._2(Prelude.$$Array.concat, items, [
          "Separator",
          {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Rename"
              },
              icon: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    setRenameFolder(function (param) {
                          return folder;
                        });
                  })
              },
              disabled: !Curry._1(Prelude.OptionExported.$$Option.isSome, renamer)
            }
          },
          {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Cut"
              },
              icon: Caml_option.some(JsxRuntime.jsx(ContentCut, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    putInClipboard$1([{
                            NAME: "folder",
                            VAL: folder
                          }]);
                  })
              },
              disabled: !hasClipboard || alreadyInClipboard$1({
                    NAME: "folder",
                    VAL: folder
                  })
            }
          },
          "Separator",
          tmp
        ]);
  } else {
    items$1 = items;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "Icon",
                        _0: JsxRuntime.jsx(MoreVert, {})
                      },
                      items: items$1,
                      disabled: props.disabled
                    }),
                renameFolder !== undefined ? JsxRuntime.jsx(RenameFolderDialog.make, {
                        folder: renameFolder,
                        onDone: (function () {
                            setRenameFolder(function (param) {
                                  
                                });
                          }),
                        onClose: (function () {
                            setRenameFolder(function (param) {
                                  
                                });
                          })
                      }) : null,
                deleteFolder !== undefined ? JsxRuntime.jsx(DeleteItemDialog.make, {
                        item: {
                          NAME: "folder",
                          VAL: deleteFolder
                        },
                        variant: deleteVariant,
                        onDone: (function () {
                            setDeleteFolder(function (param) {
                                  
                                });
                          }),
                        onClose: (function () {
                            setDeleteFolder(function (param) {
                                  
                                });
                          })
                      }) : null
              ]
            });
}

var make = FolderDropdown;

export {
  make ,
}
/* react Not a pure module */
